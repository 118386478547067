import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'

import App from './App'

process.env.REACT_APP_NODE_ENV !== 'development' &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0, //0.1
    replaysOnErrorSampleRate: 0, //0.2
  })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<App />)
