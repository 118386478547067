import theme from '@assets/themes/theme'
import ChartControl, { ExtendedDateView } from '@components/ChartControl/ChartControl'
import DateRangeModal from '@components/DateRangeModal/DateRangeModal'
import DtpCarousel from '@components/DtpCarousel/DtpCarousel'
import DtpCompareCard from '@components/DtpCompareCard/DtpCompareCard'
import { DtpDropdown } from '@components/DtpDropdown/DtpDropdown'
import DtpTabs from '@components/DtpTabs/DtpTabs'
import { AggregationEnum } from '@enums/aggregationTypes'
import { getAggregationType, isValidModalDates } from '@helpers/dateFunctions'
import { useLastAvailableDay } from '@hooks/useLastAvailableDay/useLastAvailableDay'
import useLocationsData, { IAddOn } from '@hooks/useLocations/useLocationsData'
import { CircularProgress, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const PowerConsumption = () => {
  const {
    allLocations,
    activeLocations,
    expiredLocations,
    selectedItem,
    setSelectedItem,
    isLoading,
    showExpiredContracts,
    handleChangeExpireContracts,
  } = useLocationsData()
  const { t } = useTranslation()
  const { state } = useLocation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [activeTab, setActiveTab] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [selectedFromConsumptionDetail, setSelectedFromConsumptionDetail] = useState('')
  const [customAggregationType, setCustomAggregationType] = useState<AggregationEnum>(AggregationEnum.DAY)
  const [customChartType, setCustomChartType] = useState<ExtendedDateView>('day')
  const [selectedFromComparison, setSelectedFromComparison] = useState<string>('')

  const { addOn } = selectedItem ?? {}

  const { gpnr, meterId, locationId, supplyEndDate, supplyStartDate, contractId } = addOn ?? ({ gpnr: '' } as IAddOn)

  const { lastAvailableDay: lastAvailableData, isLoadingLastAvailableData } = useLastAvailableDay({
    gpnr,
    meterId,
    contractId,
    locationId,
  })

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    newValue === 3 ? setModalOpen(!startDate.length) : setActiveTab(newValue)
  }

  const handleConfirmModal = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const aggregation = getAggregationType(startDate, endDate)
    setCustomAggregationType(aggregation)
    if (aggregation === AggregationEnum.HOUR) {
      setCustomChartType('day')
    } else if (aggregation === AggregationEnum.DAY) {
      setCustomChartType('month')
    } else if (aggregation === AggregationEnum.MONTH) {
      setCustomChartType('year')
    } else {
      setCustomChartType('years')
    }
    setStartDate(startDate)
    setEndDate(endDate)
    setActiveTab(3)
    setModalOpen(false)
  }

  const handleCustomRangeClick = () => {
    if (isValidModalDates(startDate, endDate, supplyStartDate)) {
      setActiveTab(3)
    } else {
      setModalOpen(true)
    }
  }

  useEffect(() => {
    if (state) {
      const { value, tab, billingPeriodFromDate, billingPeriodToDate } = state
      setActiveTab(tab)
      setSelectedFromConsumptionDetail(value)

      if (!!billingPeriodFromDate && !!billingPeriodToDate) {
        handleConfirmModal({
          startDate: billingPeriodFromDate,
          endDate: billingPeriodToDate,
        })
      }
    }
  }, [state, setSelectedItem, allLocations])

  useEffect(() => {
    if (selectedFromConsumptionDetail) {
      const dateFormat = selectedFromConsumptionDetail.includes('-') ? 'YYYY-MM-01T00:00:00Z' : 'YYYY-01-01T00:00:00Z'
      const date = dayjs(selectedFromConsumptionDetail).format(dateFormat)
      setSelectedFromComparison(date)
      setSelectedFromConsumptionDetail('')
    }
  }, [selectedFromConsumptionDetail])

  const tabs = [
    {
      label: t('day'),
      component: (
        <ChartControl
          addOn={selectedItem?.addOn ?? ({ gpnr: '' } as IAddOn)}
          contractStartDate={supplyStartDate}
          contractEndDate={supplyEndDate}
          chartType="day"
          selectedFromConsumptionDetail={selectedFromConsumptionDetail}
          setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
          lastAvailableData={lastAvailableData}
          isLoading={isLoadingLastAvailableData}
          startModalDate={startDate}
          endModalDate={endDate}
          aggregationType={AggregationEnum.HOUR}
          handleModalOpen={() => setModalOpen(true)}
        />
      ),
    },
    {
      label: t('month'),
      component: (
        <ChartControl
          addOn={selectedItem?.addOn ?? ({ gpnr: '' } as IAddOn)}
          contractStartDate={supplyStartDate}
          contractEndDate={supplyEndDate}
          chartType="month"
          setActiveTab={setActiveTab}
          selectedFromConsumptionDetail={selectedFromConsumptionDetail}
          setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
          lastAvailableData={lastAvailableData}
          startModalDate={startDate}
          endModalDate={endDate}
          isLoading={isLoadingLastAvailableData}
          aggregationType={AggregationEnum.DAY}
          handleModalOpen={() => setModalOpen(true)}
          selectedFromComparison={selectedFromComparison}
          setSelectedFromComparison={setSelectedFromComparison}
        />
      ),
    },
    {
      label: t('year'),
      component: (
        <ChartControl
          addOn={selectedItem?.addOn ?? ({ gpnr: '' } as IAddOn)}
          contractStartDate={supplyStartDate}
          contractEndDate={supplyEndDate}
          chartType="year"
          setActiveTab={setActiveTab}
          selectedFromConsumptionDetail={selectedFromConsumptionDetail}
          setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
          lastAvailableData={lastAvailableData}
          startModalDate={startDate}
          endModalDate={endDate}
          isLoading={isLoadingLastAvailableData}
          aggregationType={AggregationEnum.MONTH}
          handleModalOpen={() => setModalOpen(true)}
          selectedFromComparison={selectedFromComparison}
          setSelectedFromComparison={setSelectedFromComparison}
        />
      ),
    },
    {
      label: t('power_consumption_page.energy_consumption_custom_date'),
      component: (
        <ChartControl
          addOn={selectedItem?.addOn ?? ({ gpnr: '', meterId: undefined, locationId: undefined } as IAddOn)}
          contractStartDate={supplyStartDate}
          contractEndDate={supplyEndDate}
          chartType={customChartType}
          setActiveTab={setActiveTab}
          setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
          lastAvailableData={lastAvailableData}
          startModalDate={startDate}
          endModalDate={endDate}
          isLoading={isLoadingLastAvailableData}
          aggregationType={customAggregationType}
          handleModalOpen={() => setModalOpen(true)}
          customAggregation
        />
      ),
    },
  ]

  const isComparisonCardVisible = activeTab !== 3
  return (
    <>
      <Container
        sx={{
          pt: 5,
        }}
      >
        <Typography variant={'heading2'}>{t('power_consumption_page.energy_title_energy_consumption')}</Typography>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <DtpDropdown
              activeLocations={activeLocations}
              expiredLocations={expiredLocations}
              value={selectedItem.value}
              onLocationsSelect={setSelectedItem}
              noDataLabel={t('power_consumption_page.energy_no_contracts')}
              placeholder={t('power_consumption_page.energy_select_contract')}
              showExpiredContracts={showExpiredContracts}
              handleChangeExpireContracts={handleChangeExpireContracts}
              isLoading={isLoading}
            />

            <Show when={allLocations.length === 0}>
              <Typography variant="heading4" pt={3}>
                {t('power_consumption_page.error_message_no_contracts_description')}
              </Typography>
            </Show>
          </>
        )}

        <Show when={!!selectedItem?.value}>
          <DtpTabs activeTab={activeTab} onChange={handleTabChange} onClick={handleCustomRangeClick} tabs={tabs} />
        </Show>

        <Show when={isComparisonCardVisible && allLocations.length > 0}>
          {!isMobile ? (
            <Grid container mt={4}>
              <Grid item xs={6} pr={2}>
                <DtpCompareCard
                  locationId={locationId}
                  meterId={meterId}
                  contractId={contractId}
                  aggregationType="month"
                  contractStartDate={supplyStartDate}
                  contractEndDate={supplyEndDate}
                  datePickerValue={lastAvailableData ?? dayjs().format('YYYY-MM-DD')}
                  title={t('power_consumption_page.monthly_comparison')}
                  isLoading={isLoadingLastAvailableData}
                  setActiveTab={setActiveTab}
                  setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
                />
              </Grid>
              <Grid item xs={6} pl={2}>
                <DtpCompareCard
                  locationId={locationId}
                  meterId={meterId}
                  contractId={contractId}
                  contractStartDate={supplyStartDate}
                  contractEndDate={supplyEndDate}
                  aggregationType="year"
                  datePickerValue={lastAvailableData ?? dayjs().format('YYYY-MM-DD')}
                  title={t('power_consumption_page.yearly_comparison')}
                  isLoading={isLoadingLastAvailableData}
                  setActiveTab={setActiveTab}
                  setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
                />
              </Grid>
            </Grid>
          ) : (
            <DtpCarousel>
              <DtpCompareCard
                locationId={locationId}
                meterId={meterId}
                contractId={contractId}
                aggregationType="month"
                datePickerValue={lastAvailableData ?? ''}
                title={t('power_consumption_page.monthly_comparison')}
                contractEndDate={supplyEndDate}
                contractStartDate={supplyStartDate}
                isLoading={isLoadingLastAvailableData}
                setActiveTab={setActiveTab}
                setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
              />
              <DtpCompareCard
                locationId={locationId}
                meterId={meterId}
                contractId={contractId}
                contractStartDate={supplyStartDate}
                contractEndDate={supplyEndDate}
                aggregationType="year"
                datePickerValue={lastAvailableData ?? dayjs().format('YYYY-MM-DD')}
                title={t('power_consumption_page.yearly_comparison')}
                isLoading={isLoadingLastAvailableData}
                setActiveTab={setActiveTab}
                setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
              />
            </DtpCarousel>
          )}
        </Show>
      </Container>
      <DateRangeModal
        supplyStart={supplyStartDate}
        supplyEnd={supplyEndDate}
        lastAvailableDay={lastAvailableData}
        startDate={startDate}
        endDate={endDate}
        modalOpen={modalOpen}
        onConfirmModal={handleConfirmModal}
        onCloseModal={() => setModalOpen(false)}
      />
    </>
  )
}

export default PowerConsumption
