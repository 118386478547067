import { UserRoles } from '@enums/userRoles'
import { useAuth } from '@hooks/useAuth/useAuth'
import { useAuthStore } from '@src/stores/auth/useAuthStore'

const useRoleAccess = () => {
  const roles = useAuthStore((state) => state.roles)
  const { isAuthenticated, user } = useAuth()

  const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
  const isBetaTester = roles.some((role) => role.includes(UserRoles.BETA_TESTER))
  const isEndUser = roles.some((role) => role.includes(UserRoles.END_USER))
  const fullAccess = isEndUser && isBetaTester

  const disabledStyles = { pointerEvents: fullAccess ? 'auto' : 'none', opacity: fullAccess ? 1 : 0.5 }

  return { isBetaTester, isEndUser, user, isAuthenticated, disabledStyles, isProduction }
}

export default useRoleAccess
