import { getAggregationMeasurements } from '@api/queries'
import { noDataBarValue, mappedMeasurementsData } from '@components/ChartControl/ChartControlUtils'
import { IAggregationMeasurementsProps } from '@interfaces/measurements'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

const useMeasurements = (props: IAggregationMeasurementsProps) => {
  const {
    data: apiData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['aggregationYear', { ...props }],
    queryFn: () => getAggregationMeasurements(props),
    enabled: !!props.gpnr && ((!!props.meterId && !!props.contractId) || !!props.locationId),
  })

  const data = useMemo(() => {
    if (!apiData) return null
    const { body: aggregationData } = apiData
    mappedMeasurementsData(aggregationData)
    noDataBarValue(aggregationData)
    return aggregationData
  }, [apiData])

  return { data, isLoading, isFetching }
}

export default useMeasurements
