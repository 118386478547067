import { Container, Box, styled } from '@mui/material'
import React from 'react'

interface IHeroContainerProps {
  children: React.ReactNode
}

const BoxWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  boxShadow: '0px 10px 15px -3px #0000001A',
  borderRadius: '24px',
  marginTop: 27,
  marginBottom: 40,
  overflow: 'hidden',
  padding: theme.spacing(4, 3),
  [theme.breakpoints.up('md')]: {
    marginTop: 55,
    marginBottom: 64,
    padding: theme.spacing(6, 4),
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: 55,
    padding: theme.spacing(6, 12),
  },
}))

export const HeroContainer = ({ children }: IHeroContainerProps) => {
  return (
    <Container>
      <BoxWrapper> {children}</BoxWrapper>
    </Container>
  )
}
