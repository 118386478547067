import { Stack, Typography, Button } from '@mui/material'
import { Trans } from 'react-i18next'

const GoToManualAuth = ({ onClick }: { onClick: () => void }) => {
  return (
    <Stack mt={3}>
      <Typography variant="bodyRegular" sx={{ m: 0, display: 'inline-block' }}>
        <Trans
          i18nKey="authorization.go_to_manual_authorization"
          components={{
            button: (
              <Button
                variant="text"
                disableTouchRipple
                size="large"
                sx={{
                  p: '0 !important',
                  minWidth: { xs: 'fit-content' },
                  lineHeight: 'inherit',
                  height: 'inherit',
                }}
                onClick={onClick}
              />
            ),
          }}
        />
      </Typography>
    </Stack>
  )
}

export default GoToManualAuth
