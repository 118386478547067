import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const createKeycloakAxiosInstance = (baseURL: string) => {
  return axios.create({ baseURL, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
}

export const createApiInstance = (baseURL: string) => {
  const apiInstance: AxiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  apiInstance.interceptors.request.use(async (request) => {
    await setAccessTokenOnRequestAndAsAxiosDefaults(apiInstance, request)
    return request
  }, Promise.reject)

  return apiInstance
}

export const setAccessTokenOnRequestAndAsAxiosDefaults = async (
  axiosInstance: AxiosInstance,
  request: AxiosRequestConfig
) => {
  const token = localStorage.getItem('ckw-token')
  if (token) {
    const authHeader = `Bearer ${JSON.parse(token)}`
    if (!request.headers) request.headers = {}
    request.headers.Authorization = authHeader
    axiosInstance.defaults.headers.common['Authorization'] = authHeader
  }
}

const api = createApiInstance(process.env.REACT_APP_API_URL as string)
const apiAccount = createApiInstance(process.env.REACT_APP_ACCOUNT_API_URL as string)
const apiUser = createApiInstance(process.env.REACT_APP_USER_API_URL as string)
const apiInvoice = createApiInstance(process.env.REACT_APP_INVOICE_API_URL as string)
const apiAuthorization = createApiInstance(process.env.REACT_APP_AUTHORIZATION_API_URL as string)
const apiKeycloak = createKeycloakAxiosInstance(process.env.REACT_APP_KEYCLOAK_URL as string)

export { api, apiAccount, apiUser, apiInvoice, apiAuthorization, apiKeycloak }
