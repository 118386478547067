import { prosumerPalette } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import ProsumerItem from '@components/ProsumerItem/ProsumerItem'
import ConsumptionSumSkeleton from '@components/Skeletons/ConsumptionSumSkeleton'
import { IFeedInAggregationMeasurement } from '@interfaces/measurements'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Stack, Divider, Paper, useMediaQuery, Typography, Button, styled, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProsumerLableProps {
  data?: IFeedInAggregationMeasurement | null
  unit: 'CHF' | 'kWh'
  isLoading?: boolean
}

const TextField = ({ text }: { text: string }) => {
  return (
    <Typography sx={{ my: 0 }} color={theme.palette.text.primary} fontFamily="GothamBook" variant="bodySmall">
      {text}
    </Typography>
  )
}

const CustomButton = styled(Button)({
  fontSize: '16px',
  letterSpacing: '0em',
  padding: 0,
  marginTop: theme.spacing(2),
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
})

export const ProsumerLable = ({ data, unit, isLoading }: IProsumerLableProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileLarge = useMediaQuery(theme.breakpoints.down('md'))

  const feedInValue = data?.feedInSum ?? data?.sumFeedIn
  const consumptionValue = data?.consumptionSum ?? data?.sumEnergy

  const prosumerFeedIn = feedInValue ? Number(feedInValue).toFixed(2) : '-'
  const prosumerConsumption = consumptionValue ? Number(consumptionValue).toFixed(2) : '-'
  const prosumerBalance = data?.balance ? Number(data?.balance).toFixed(2) : '-'

  if (isLoading) return <ConsumptionSumSkeleton />

  return (
    <Paper
      variant={isMobile ? 'outlined' : 'elevation'}
      elevation={0}
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
        [theme.breakpoints.down('sm')]: {
          backgroundColor: theme.palette.grey[50],
          p: 1,
          mx: 0,
          borderRadius: 4,
        },
      }}
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent={isMobile ? 'space-evenly' : 'end'}
        spacing={isMobileLarge ? 0.5 : 2}
      >
        <ProsumerItem
          label={t('prosumer.prosumer_feed_in')}
          value={prosumerFeedIn}
          unit={unit}
          showSquare={true}
          color={prosumerPalette.prosumerFeedIn}
          componentVariant="ProsumerLable"
          showTooltip
          tooltipContent={
            <>
              <TextField text={t('prosumer.prosumer_feed_in_explanation')} />
              <CustomButton variant={'text'} startIcon={<ArrowForwardIcon />}>
                {t('learn_more')}
              </CustomButton>
            </>
          }
        />
        <Divider orientation="vertical" flexItem />
        <ProsumerItem
          label={t('prosumer.prosumer_consumption')}
          value={prosumerConsumption}
          unit={unit}
          showSquare={true}
          color={prosumerPalette.prosumerConsumption}
          componentVariant="ProsumerLable"
          showTooltip
          tooltipContent={
            <>
              <TextField text={t('prosumer.prosumer_consumption_explanation')} />
              <CustomButton variant={'text'} startIcon={<ArrowForwardIcon />}>
                {t('learn_more')}
              </CustomButton>
            </>
          }
        />
        {!isMobile && <Divider orientation="vertical" flexItem />}
        <Box flex={isMobile ? 1 : 0}>
          {isMobile && <Divider sx={{ my: '6px' }} orientation="horizontal" />}
          <ProsumerItem
            label={t('prosumer.prosumer_balance')}
            value={prosumerBalance}
            valueName="balance"
            unit={unit}
            showSquare={false}
            componentVariant="ProsumerLable"
            showTooltip
            tooltipContent={
              <>
                <TextField text={t('prosumer.prosumer_balance_explanation')} />
                <CustomButton variant={'text'} startIcon={<ArrowForwardIcon />}>
                  {t('learn_more')}
                </CustomButton>
              </>
            }
          />
        </Box>
      </Stack>
    </Paper>
  )
}
