import { createContext, useContext, useState, Children, isValidElement } from 'react'

type WizardContextProps = {
  steps: string[]
  currentStepIndex: number
  currentStepId: string
  next: () => void
  previous: () => void
  goTo: (id: string) => void
  goBack: () => void
  resetSteps: () => void
}

const WizardContext = createContext<WizardContextProps | undefined>(undefined)

function useWizard() {
  const context = useContext(WizardContext)

  if (!context) {
    throw new Error('useWizard must be used within a WizardProvider')
  }

  return context
}

type WizardProps = Readonly<{
  children: React.ReactNode
  currentStep?: number
}>

function Wizard({ children, currentStep = 0 }: WizardProps) {
  const steps = Children.toArray(children)
    .filter(
      (child): child is React.ReactElement<{ id: string }> =>
        isValidElement(child) &&
        child.type === Step &&
        typeof child.props === 'object' &&
        child.props !== null &&
        'id' in child.props
    )
    .map((child) => (child.props as { id: string }).id)

  const [currentStepIndex, setCurrentStepIndex] = useState(currentStep)
  const [previousStepIndex, setPreviousStepIndex] = useState<number | null>(null)

  const next = () => {
    setPreviousStepIndex(currentStepIndex)
    setCurrentStepIndex((prev) => Math.min(prev + 1, steps.length - 1))
  }

  const previous = () => {
    setCurrentStepIndex((prev) => Math.max(prev - 1, 0))
    setPreviousStepIndex(null)
  }

  const goBack = () => {
    if (previousStepIndex !== null) {
      setCurrentStepIndex(previousStepIndex)
      setPreviousStepIndex(null)
    }
  }

  const goTo = (id: string) => {
    const index = steps.findIndex((stepId) => stepId === id)
    if (index !== -1 && index !== currentStepIndex) {
      setPreviousStepIndex(currentStepIndex)
      setCurrentStepIndex(index)
    }
  }

  const resetSteps = () => {
    if (currentStepIndex !== 0) {
      setCurrentStepIndex(0)
      setPreviousStepIndex(null)
    }
  }

  const currentStepId = steps[currentStepIndex]

  const value = {
    steps,
    currentStepIndex,
    currentStepId,
    next,
    previous,
    goTo,
    goBack,
    resetSteps,
  }

  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
}

type StepProps<T> = Readonly<{
  id: string
  component: React.ComponentType<T>
  [key: string]: unknown
}>

function Step<T>({ component: Component, id, ...props }: StepProps<T>) {
  const wizardProps = useWizard()

  if (id !== wizardProps.currentStepId) {
    return null
  }

  return <Component id={id} {...wizardProps} {...(props as T)} />
}

Wizard.Step = Step

export { useWizard, Wizard }
