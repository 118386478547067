import GoToManualAuth from '@components/DtpConnectAccountWizard/common/GoToManualAuth'
import TooltipLabel from '@components/DtpConnectAccountWizard/common/TooltipLabel'
import Wrapper from '@components/DtpConnectAccountWizard/common/Wrapper'
import DtpTextField from '@components/DtpTextField/DtpTextField'
import { FormSteps } from '@enums/authorization'
import { IEmailConfirmation } from '@interfaces/authorization'
import { Box, Button, Typography } from '@mui/material'
import { useMask } from '@react-input/mask'
import { automaticAuthSchema } from '@src/validations/authorizationValidationSchema'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export interface IOneTimePasswordProps {
  otp: string
  goTo: (step: string) => void
  emailConfirmation: (data: IEmailConfirmation) => void
}

const OneTimePassword = ({ otp, emailConfirmation, goTo }: IOneTimePasswordProps) => {
  const { t } = useTranslation()

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      otp: otp.replaceAll('-', ' '),
    },
    validateOnChange: true,
    validationSchema: automaticAuthSchema,
    onSubmit: ({ otp }) => emailConfirmation({ otp: otp.replaceAll(' ', '-') }),
  })

  const otpInputRef = useMask({ mask: '___ ___ ___ ___', replacement: '_' })

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pastedText = e.clipboardData.getData('text')
    const removedDashes = pastedText.replaceAll('-', ' ')
    setFieldValue('otp', removedDashes)
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Box mb={4}>
          <Typography variant="heading3">{t('authorization.finalise_link')}</Typography>
          <Typography variant="bodyRegular">{t('authorization.finalise_link_description')}</Typography>
        </Box>
        <DtpTextField
          value={values.otp}
          onChange={handleChange}
          onBlur={handleBlur}
          name="otp"
          onPaste={handlePaste}
          inputRef={otpInputRef}
          label={
            <TooltipLabel
              label={t('authorization.confirmation_code')}
              tooltipLabel={t('authorization.tooltip_confirmation_code')}
            />
          }
          inputMode="tel"
          helperText={touched.otp ? errors.otp : undefined}
          error={touched.otp && Boolean(errors.otp)}
          placeholder="xxx xxx xxx"
          fullWidth
        />
        <Box mt={3}>
          <Button type="submit" disabled={!values.otp || Object.keys(errors).length > 0}>
            {t('authorization.complete')}
          </Button>
        </Box>
        <GoToManualAuth onClick={() => goTo(FormSteps.ACCOUNT_NUMBER_STEP)} />
      </form>
    </Wrapper>
  )
}

export default OneTimePassword
