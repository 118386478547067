import { chartPallete } from '@assets/themes/palette'
import ConsumptionItem from '@components/ConsumptionItem/ConsumptionItem'
import { isItNullValue } from '@helpers/chartAreaFunctions'
import { isNil } from '@helpers/functional'
import Show from '@src/ui/wrappers/Show/Show'
import { TFunction } from 'i18next'

interface ConsumptionTooltipProps {
  lowRateValue: string | null
  highRateValue: string | null
  sumOfRatesValue: string | null
  unit: string
  t: TFunction
}

const ConsumptionTooltip = ({ lowRateValue, highRateValue, sumOfRatesValue, unit, t }: ConsumptionTooltipProps) => {
  return (
    <>
      <Show when={!isNil(lowRateValue) && !isItNullValue(sumOfRatesValue)}>
        <ConsumptionItem
          label={t('power_consumption_page.low_rate')}
          value={String(Number(lowRateValue).toFixed(2))}
          unit={unit}
          showSquare={true}
          color={chartPallete.bar.lowRate.fill}
          componentVariant="ChartTooltip"
        />
      </Show>
      <Show when={!isNil(highRateValue) && !isItNullValue(sumOfRatesValue)}>
        <ConsumptionItem
          label={t('power_consumption_page.high_rate')}
          value={String(Number(highRateValue).toFixed(2))}
          unit={unit}
          showSquare={true}
          color={chartPallete.bar.highRate.fill}
          componentVariant="ChartTooltip"
        />
      </Show>
    </>
  )
}

export default ConsumptionTooltip
