import { useFeatureToggle } from '@hooks/useFeatureToggle/useFeatureToggle'
import React from 'react'

interface IFeatureProps {
  flag: string
  children: React.ReactNode
  fallback?: React.ReactNode
}

const FeatureToggle = ({ flag, children, fallback }: IFeatureProps) => {
  const { features } = useFeatureToggle()
  const isEnabled = features[flag]

  const hasFallback = fallback ? <>{fallback}</> : null

  return isEnabled ? <>{children}</> : hasFallback
}

export default FeatureToggle
