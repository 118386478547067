import ChartLoader from '@components/ChartLoader/ChartLoader'
import { ChartTooltip } from '@components/ChartTooltip/ChartTooltip'
import { handleTooltipOpenAndPositionCompare } from '@helpers/chartBarFunctions'
import { IMeasurement, MeasurmentsType } from '@interfaces/measurements'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'

import { CustomizedBar } from './CustomBar'
import './style.css'

export interface IComparationChartProps {
  data: (IMeasurement | undefined)[]
  unit?: 'kWh' | 'CHF'
  isLoading?: boolean
  aggregationType?: 'month' | 'year'
  handleOnDateChange?: CallableFunction
  measurementType?: MeasurmentsType
}

const ComparationChart = ({
  data = [],
  unit = 'kWh',
  isLoading = false,
  aggregationType = 'month',
  handleOnDateChange,
  measurementType,
}: IComparationChartProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const TOOLTIP_INITIAL_X = 1
  const TOOLTIP_INITIAL_Y = isMobile ? 0 : -240

  const [tooltipPosition, setTooltipPosition] = useState({ x: TOOLTIP_INITIAL_X, y: TOOLTIP_INITIAL_Y })
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const [wrapperStylePositionTooltip, setWrapperStylePositionTooltip] = useState<{ left: number; top: number }>()
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))

  useEffect(() => {
    if (isLoading && tooltipPosition.x !== TOOLTIP_INITIAL_X)
      setTooltipPosition({ x: TOOLTIP_INITIAL_X, y: TOOLTIP_INITIAL_Y })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const onMouseClick = (e: {
    tooltipPosition: object
    forecast: string
    timestampStartOfPeriod: string
    x: number
    y: number
    width: number
    height: number
  }) => {
    return handleTooltipOpenAndPositionCompare({
      e,
      tooltipOpen,
      setTooltipOpen,
      setTooltipPosition,
      isSmallMobile,
      setWrapperStylePositionTooltip,
      TOOLTIP_INITIAL_X,
      TOOLTIP_INITIAL_Y,
      viewportWidth,
    })
  }

  const tickFormatter = (value: string | number, index: number) => {
    const localStringAdditionWithForecast = data[index]?.forecast ? '*' : ''
    const localStringValue = Number(value).toLocaleString('de-CH', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return value && +value > 0 ? `${localStringValue} ${unit}${localStringAdditionWithForecast}` : ''
  }

  if (isLoading) {
    return <ChartLoader sx={{ minHeight: 200 }} />
  }
  if (!data.length)
    return (
      <Box pb={4.5}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={165}
          textAlign="center"
          borderBottom="2px solid #BFC4BF"
        >
          <Typography color="#4F544F" variant="bodySmallEmphasized" width={isMobile ? '70%' : '50%'}>
            {t('power_consumption_page.error_message_no_data_available') +
              ', ' +
              t('power_consumption_page.comparison_no_for_combination')}
          </Typography>
        </Stack>
      </Box>
    )

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={data} barSize={41}>
        <XAxis
          tickLine={false}
          dataKey={measurementType ?? 'consumption'}
          padding="no-gap"
          fontSize={isMobile ? 14 : 16}
          fill="#BFC4BF"
          tickFormatter={tickFormatter}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={
            tooltipPosition.x !== TOOLTIP_INITIAL_X || isSmallMobile ? (
              <ChartTooltip
                handleOnDateChange={handleOnDateChange}
                tooltipPosition={tooltipPosition.x}
                setTooltipOpen={setTooltipOpen}
                unit={unit}
                chartType="ComparationChart"
                aggregationType={aggregationType}
                chartOrigin="ComparationChart"
                id={`comparationTooltip-${aggregationType}`}
                mesurementsType={measurementType}
              />
            ) : undefined
          }
          position={tooltipPosition}
          allowEscapeViewBox={{ x: false, y: true }}
          trigger={'click'}
          active={tooltipOpen}
          animationDuration={300}
          wrapperStyle={isSmallMobile ? wrapperStylePositionTooltip : undefined}
        />

        <Bar
          activeBar={false}
          className="comparationBar"
          dataKey={measurementType ?? 'consumption'}
          stackId="a"
          shape={<CustomizedBar aggregationType={aggregationType} isMobile={isMobile} />}
          onClick={onMouseClick}
        />

        <Bar
          dataKey="forecast"
          stackId="a"
          stroke="#B161A280"
          strokeDasharray="2 2"
          strokeWidth={1}
          fill="#B161A233"
          onClick={onMouseClick}
          cursor="pointer"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ComparationChart
