import ProsumerItem from '@components/ProsumerItem/ProsumerItem'
import { isItNullValue } from '@helpers/chartAreaFunctions'
import { isNil } from '@helpers/functional'
import { Divider } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { TFunction } from 'i18next'

interface FeedInTooltipProps {
  shouldShowFeedInItem: boolean
  feedIn: number | string | undefined
  t: TFunction
  unit: string
  color: string
  feedInValueForProsumerWhenMeasured: string | null | undefined
  feedInMeasured: string | number | undefined
}

const FeedInTooltip = ({
  shouldShowFeedInItem,
  feedIn,
  t,
  unit,
  color,
  feedInValueForProsumerWhenMeasured,
  feedInMeasured,
}: FeedInTooltipProps) => {
  return (
    <>
      <Show when={!isItNullValue(feedInValueForProsumerWhenMeasured) && !isNil(feedInMeasured)}>
        <ProsumerItem
          label={t('prosumer.prosumer_measured')}
          value={String(Number(feedInMeasured).toFixed(2))}
          unit={unit}
          showSquare={false}
          color={color}
          componentVariant="ChartTooltip"
        />
        <ProsumerItem
          label={t('prosumer.prosumer_invoiced')}
          value={String(Number(feedIn).toFixed(2))}
          unit={unit}
          showSquare={false}
          color={color}
          componentVariant="ChartTooltip"
        />
        <Divider sx={{ mt: 1, mb: 1 }} />

        <ProsumerItem
          label={t('prosumer.prosumer_feed_in')}
          value={String(Number(feedInValueForProsumerWhenMeasured).toFixed(2))}
          unit={unit}
          showSquare={true}
          color={color}
          componentVariant="ChartTooltip"
        />
      </Show>

      <Show when={shouldShowFeedInItem}>
        <ProsumerItem
          label={t('prosumer.prosumer_feed_in')}
          value={!isNil(feedIn) ? String(Number(feedIn).toFixed(2)) : null}
          unit={unit}
          showSquare={true}
          color={isItNullValue(feedIn) ? 'transparent' : color}
          border={isItNullValue(feedIn) ? 'rgba(141, 150, 141, 0.5)' : undefined}
          componentVariant="ChartTooltip"
        />
      </Show>
    </>
  )
}

export default FeedInTooltip
