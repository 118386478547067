import GoToManualAuth from '@components/DtpConnectAccountWizard/common/GoToManualAuth'
import Wrapper from '@components/DtpConnectAccountWizard/common/Wrapper'
import { FormSteps } from '@enums/authorization'
import useNotification from '@hooks/useNotification/useNotification'
import { Box, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface ISendVerificationEmailProps {
  goTo: (step: string) => void
  emailInitiation: () => Promise<{ body: null }>
}

const SendVerificationEmail = ({ goTo, emailInitiation }: ISendVerificationEmailProps) => {
  const { t } = useTranslation()
  const notification = useNotification()

  const handleEmailInitiation = async () => {
    try {
      await emailInitiation()
      notification(
        'success',
        t('authorization.automatic_authorization_email_sent_title'),
        t('authorization.automatic_authorization_email_sent_description')
      )
    } catch (error) {
      notification('error')
    }
  }
  return (
    <Wrapper>
      <Box>
        <Box mb={4}>
          <Typography variant="heading3">{t('authorization.send_confirmation_email')}</Typography>
          <Typography variant="bodyRegular">{t('authorization.automatic_link_account_description')}</Typography>
        </Box>

        <Button size="large" onClick={handleEmailInitiation}>
          {t('authorization.send_email')}
        </Button>

        <GoToManualAuth onClick={() => goTo(FormSteps.ACCOUNT_NUMBER_STEP)} />
      </Box>
    </Wrapper>
  )
}

export default SendVerificationEmail
