import { ConstructionAreaSignIcon } from '@assets/icons'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Maintenance = () => {
  const { t } = useTranslation()
  return (
    <Stack height="100vh" direction="row" justifyContent="center" textAlign="center">
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '625px',
          },
          p: {
            xs: 4,
            md: 1,
          },
        }}
        alignSelf="center"
        gap={{ xs: 1, sm: 2 }}
        display="flex"
        flexDirection="column"
        alignItems={'center'}
      >
        <ConstructionAreaSignIcon sx={{ height: 64, width: 64, color: 'transparent' }} />
        <Typography variant="heading2" sx={{ m: 0 }} fontFamily="GothamMedium">
          {t('maintenance_page.maintenance_work')}
        </Typography>
        <Typography variant="lead" fontWeight={400} sx={{ m: 0 }} fontFamily="GothamBook">
          {t('maintenance_page.maintenance_description')}
        </Typography>
      </Box>
    </Stack>
  )
}

export default Maintenance
