import { AreachartIcon, BarchartIcon, BarchartWithLineIcon } from '@assets/icons'
import i18n from 'i18next'

const { t } = i18n

export const switchOptions = [
  {
    label: t('power_consumption_page.energy_consumption_label'),
    value: '1',
    icon: <BarchartIcon />,
  },
  {
    label: t('power_consumption_page.peak_power'),
    value: '2',
    icon: <AreachartIcon />,
  },
  {
    label: t('power_consumption_page.reactive_power'),
    value: '3',
    icon: <BarchartWithLineIcon />,
  },
]
