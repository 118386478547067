import { getComparisonData } from '@api/queries'
import { comparisonDataPrepack } from '@helpers/comparisonFunctions'
import { getAggregationsStartEndDate } from '@helpers/dateFunctions'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { IMeasurement, IComparisonApiResponse, MeasurmentsType } from '@interfaces/measurements'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

interface IUseComparisonProps {
  datePickerValue: string
  aggregationType: 'month' | 'year'
  locationId?: string
  meterId?: string
  contractId?: string
  contractStartDate?: string
  contractEndDate?: string
  period1: string
  period2: string
}

const useComparison = ({
  datePickerValue,
  aggregationType,
  locationId,
  meterId,
  contractId,
  contractStartDate,
  contractEndDate,
  period1,
  period2,
}: IUseComparisonProps) => {
  const { activeAccount } = useAccounts()
  const gpnr = activeAccount?.gpnr

  const params = {
    gpnr,
    locationId: locationId ?? undefined,
    meterId: meterId ?? undefined,
    contractId: contractId ?? undefined,
    aggregation: aggregationType.toUpperCase(),
    period1: { ...getAggregationsStartEndDate(period1, aggregationType) },
    period2: { ...getAggregationsStartEndDate(period2, aggregationType) },
  }

  const { data, isLoading: isLoadingComparation } = useQuery<IComparisonApiResponse>({
    queryKey: ['comparisonData', { ...params }],
    queryFn: () => getComparisonData(params),
    enabled:
      !!gpnr &&
      (!!locationId || (!!meterId && !!contractId)) &&
      !!contractStartDate &&
      !!contractEndDate &&
      !!datePickerValue &&
      !!period1 &&
      !!period2,
    gcTime: 0,
  })

  const measurementType = data?.body.type as MeasurmentsType

  const comparationData = useMemo(() => {
    if (!data?.body) return []
    const modifiedData = comparisonDataPrepack(data.body)
    modifiedData.forEach((measurement: IMeasurement | undefined) => {
      if (measurement?.value) {
        if (measurementType === 'feedIn') {
          measurement.feedIn = String(measurement.value)
        } else if (measurementType === 'balance') {
          measurement.balance = String(measurement.value)
        } else {
          measurement.consumption = String(measurement.value)
        }
      }
    })
    return modifiedData
  }, [data, measurementType])

  return { comparationData, isLoadingComparation, measurementType }
}

export default useComparison
