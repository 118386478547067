import ContactSupportContent from '@components/DtpConnectAccountWizard/common/ContactSupportContent'
import Wrapper from '@components/DtpConnectAccountWizard/common/Wrapper'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ContactSupport = ({ goBack, accountNumber }: { goBack: () => void; accountNumber?: string }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Box>
        <Typography variant="heading3" sx={{ mt: 0, mb: 2 }}>
          {t('authorization.no_contact_details_available')}
        </Typography>
        <ContactSupportContent accountNumber={accountNumber} />
        <Button variant="outlined" size="large" onClick={goBack}>
          {t('button_close')}
        </Button>
      </Box>
    </Wrapper>
  )
}

export default ContactSupport
