import FooterNavigation from '@components/Navigation/FooterNavigation'
import { SuspenseLoading } from '@components/SuspenseLoading/SuspenseLoading'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { Stack, styled } from '@mui/material'
import { Suspense } from 'react'

import { useAuth } from '../../../hooks/useAuth/useAuth'
import { PublicRoutes, Routes } from '../../../router/Routes'
import Navigation from '../../components/Navigation/Navigation'

const AppWrapper = styled(Stack)({
  minHeight: '100vh',
  width: '100%',
})

const PrivateRoutes = () => {
  return (
    <>
      <Navigation />
      <Routes />
      <FooterNavigation />
    </>
  )
}

export const AppContainer = () => {
  const { keycloakError } = useAuth()
  const { isServerError } = useAccounts()

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <AppWrapper>{keycloakError || isServerError ? <PublicRoutes /> : <PrivateRoutes />}</AppWrapper>
    </Suspense>
  )
}
