import { UserRoles } from '@enums/userRoles'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type ValueOf<T> = T[keyof T]
export type Role = ValueOf<typeof UserRoles>

type RolesState = {
  roles: Role[] | []
  setRoles: (roles: Role[]) => void
  clearRoles: () => void
}

export const useAuthStore = create<RolesState>()(
  persist(
    (set) => ({
      roles: [],
      setRoles: (roles: Role[]) => {
        set({ roles })
      },
      clearRoles: () => set({ roles: [] }),
    }),
    {
      name: 'roles-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
