import CodeDeliveryOptions from '@components/DtpConnectAccountWizard/common/CodeDeliveryOptions'
import NoAccountControls from '@components/DtpConnectAccountWizard/common/NoAccountControls'
import { AuthOptions, FormSteps } from '@enums/authorization'
import useNotification from '@hooks/useNotification/useNotification'
import CloseIcon from '@mui/icons-material/Close'
import { Typography, Button, Stack, useTheme, useMediaQuery, IconButton } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

const mediumMap = {
  invoiceEmail: 'E-mail',
  mobileNumber: 'SMS',
}

export interface IRequestNewCodeProps {
  authOptions: Partial<Record<AuthOptions, string>>
  goTo: (step: string) => void
  requestAccessCode: (medium: 'invoiceEmail' | 'mobileNumber') => Promise<{ body: null }>
  onClose?: () => void
  setSelectedOption: (method: AuthOptions) => void
  selectedOption: AuthOptions
  availableDeliveryOptions: {
    [key: string]: boolean
  }
}

const RequestNewCode = ({
  goTo,
  authOptions,
  requestAccessCode,
  onClose,
  selectedOption,
  setSelectedOption,
  availableDeliveryOptions,
}: IRequestNewCodeProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const notification = useNotification()

  const handleRequestAccessCode = async () => {
    try {
      await requestAccessCode(selectedOption)
      notification(
        'success',
        t('authorization.access_code_sent_title'),
        t('authorization.access_code_sent_description', { medium: mediumMap[selectedOption] })
      )
      goTo(FormSteps.ACCESS_CODE_STEP)
    } catch (error) {
      notification('error')
    }
  }

  return (
    <Stack height="100%">
      <Stack mb={4} direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="heading3" sx={{ my: 0, mt: 0.5 }}>
          {t('authorization.request_access_code')}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>

      <Typography variant="bodyRegular" sx={{ mb: { xs: 2, sm: 4 } }}>
        {t('authorization.choose_access_code_option')}
      </Typography>

      <CodeDeliveryOptions
        authOptions={authOptions}
        availableDeliveryOptions={availableDeliveryOptions}
        selectedOption={selectedOption}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedOption(e.target.value as AuthOptions)}
      />

      <Stack height="100%" direction={{ xs: 'column-reverse', sm: 'column' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" gap={1} mt="auto">
          <Show when={!availableDeliveryOptions.none}>
            <Button variant="contained" size="large" fullWidth={isMobile} onClick={handleRequestAccessCode}>
              {t('authorization.request_code')}
            </Button>
          </Show>
          <Button variant="outlined" size="large" fullWidth={isMobile} onClick={() => goTo(FormSteps.ACCESS_CODE_STEP)}>
            {t('back')}
          </Button>
        </Stack>
        <NoAccountControls onClick={() => goTo(FormSteps.SUPPORT)} />
      </Stack>
    </Stack>
  )
}

export default RequestNewCode
