import { requestAccessCodeMethod } from '@api/mutations'
import { getAuthOptions } from '@api/queries'
import { AuthOptions } from '@enums/authorization'
import { removeBlankSpaces } from '@helpers/functional'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useMemo, useEffect, useState } from 'react'

const useAuthOptions = (customerNumber: string) => {
  const [selectedOption, setSelectedOption] = useState<AuthOptions>(AuthOptions.SMS)

  const gpnr = removeBlankSpaces(customerNumber)

  const { data: authOptionsData, isFetching: isLoadingAuthOptions } = useQuery({
    queryKey: ['auth-options', gpnr],
    queryFn: () => getAuthOptions(gpnr),
    enabled: !!gpnr,
  })

  const { mutateAsync: requestAccessCode } = useMutation({
    mutationKey: ['requestAccessCode'],
    mutationFn: (medium: 'invoiceEmail' | 'mobileNumber') => requestAccessCodeMethod({ gpnr, medium }),
  })

  const authOptions = useMemo(() => authOptionsData?.body ?? {}, [authOptionsData])

  const { mobileNumber, invoiceEmail } = authOptions

  const availableDeliveryOptions = {
    sms: !!mobileNumber && !invoiceEmail,
    email: !!invoiceEmail && !mobileNumber,
    all: !!mobileNumber && !!invoiceEmail,
    none: !mobileNumber && !invoiceEmail,
  } as const

  useEffect(() => {
    const hasSmsOption = !!mobileNumber && !invoiceEmail
    const hasEmailOption = !!invoiceEmail && !mobileNumber
    if (hasEmailOption) {
      setSelectedOption(AuthOptions.EMAIL)
    }
    if (hasSmsOption) {
      setSelectedOption(AuthOptions.SMS)
    }
  }, [invoiceEmail, mobileNumber])

  return {
    availableDeliveryOptions,
    authOptions,
    isLoadingAuthOptions,
    selectedOption,
    setSelectedOption,
    requestAccessCode,
  }
}

export default useAuthOptions
