import ConsumptionItem from '@components/ConsumptionItem/ConsumptionItem'
import ProsumerItem from '@components/ProsumerItem/ProsumerItem'
import { isItNullValue } from '@helpers/chartAreaFunctions'
import { isNil } from '@helpers/functional'
import { Divider } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { TFunction } from 'i18next'

interface EnergyTooltipProps {
  shouldShowEnergyItem: boolean
  t: TFunction
  unit: string
  color: string
  energy: number | string | undefined
  energyValueForProsumerWhenMeasured: string | null | undefined
  energyMeasured: string | number | undefined
}

const EnergyTooltip = ({
  shouldShowEnergyItem,
  energy,
  t,
  unit,
  color,
  energyValueForProsumerWhenMeasured,
  energyMeasured,
}: EnergyTooltipProps) => {
  return (
    <>
      <Show when={!isItNullValue(energyValueForProsumerWhenMeasured) && !isNil(energyMeasured)}>
        <ConsumptionItem
          label={t('prosumer.prosumer_measured')}
          value={String(Number(energyMeasured).toFixed(2))}
          unit={unit}
          showSquare={false}
          componentVariant="ChartTooltip"
        />
        <ConsumptionItem
          label={t('prosumer.prosumer_invoiced')}
          value={String(Number(energy).toFixed(2))}
          unit={unit}
          showSquare={false}
          componentVariant="ChartTooltip"
        />
        <Divider sx={{ mt: 1, mb: 1 }} />
        <ConsumptionItem
          label={t('consumption.energy')}
          value={String(Number(energyValueForProsumerWhenMeasured).toFixed(2))}
          unit={unit}
          showSquare={true}
          color={color}
          componentVariant="ChartTooltip"
        />
      </Show>
      <Show when={shouldShowEnergyItem}>
        <ProsumerItem
          label={t('consumption.energy')}
          value={energy !== undefined && !isItNullValue(energy) ? String(Number(energy).toFixed(2)) : null}
          unit={unit}
          showSquare={true}
          color={isItNullValue(energy) ? 'transparent' : color}
          border={isItNullValue(energy) ? 'rgba(141, 150, 141, 0.5)' : undefined}
          componentVariant="ChartTooltip"
        />
      </Show>
    </>
  )
}

export default EnergyTooltip
