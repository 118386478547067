import { LogoIcon } from '@assets/icons'
import { useFeatureToggle } from '@hooks/useFeatureToggle/useFeatureToggle'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useNavigate } from 'react-router-dom'

import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const Navigation = () => {
  const navigate = useNavigate()
  const { features } = useFeatureToggle()
  const isMaintenance = features['maintenance']

  return (
    <AppBar color="default" position="relative" sx={{ padding: 0, zIndex: 2 }}>
      <Toolbar>
        <IconButton onClick={() => navigate('/')} disableRipple sx={{ mr: 2 }}>
          <LogoIcon data-testid="logo-icon" sx={{ width: '100%', height: { xs: '34px', md: '51px' } }} />
        </IconButton>
        <Show when={!isMaintenance}>
          <DesktopMenu />
          <MobileMenu />
        </Show>
      </Toolbar>
    </AppBar>
  )
}

export default Navigation
