import { chartPallete, comparisonPalette, prosumerPalette, switchComponentBackground } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import ConsumptionItem from '@components/ConsumptionItem/ConsumptionItem'
import ProsumerItem from '@components/ProsumerItem/ProsumerItem'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { isItNullValue } from '@helpers/chartAreaFunctions'
import { isNil } from '@helpers/functional'
import { usePrevious } from '@hooks/usePrevious'
import type { MeasurmentsType } from '@interfaces/measurements'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Stack, Typography, Divider, Box, Button, styled, useMediaQuery } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ConsumptionTooltip from './ConsumptionTooltip'
import EnergyTooltip from './EnergyTooltip'
import FeedInTooltip from './FeedInTooltip'

export interface PayloadItem {
  payload: {
    timestampStartOfPeriod: string
    amountLowRate: string | null
    amountLowRateQuality?: string
    amountLowRateCharged?: string
    amountHighRate: string | null
    amountHighRateQuality?: string
    amountHighRateCharged?: string
    forecast?: string
    forecastCharge?: string
    sumOfRates: string
    feedIn?: string | number
    feedInMeasured?: string | number
    consumption?: string | number
    balance?: string | number
    energy?: string
    energyChf?: string
    energyMeasured?: string | number
    averageEnergy?: string
    averageMaxPower?: string
    maxPower?: string
    reactivePower?: string
    description?: string
    noDataValue?: number | null
  }
}

export interface CustomTooltipProps {
  active?: boolean
  payload?: PayloadItem[]
  unit: string
  setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>
  tooltipPosition: number
  chartType?: string
  aggregationType?: string
  handleOnDateChange?: CallableFunction
  chartOrigin?: 'ChartArea' | 'ChartBar' | 'ComparationChart'
  id?: string
  activeBar?: string
  maxPower?: {
    timestamp: string
    maxPower: string | number
  }
  mesurementsType?: MeasurmentsType
}
interface CustomEvent extends MouseEvent {
  target: EventTarget & {
    nodeName: string
  }
}

const CustomBox = styled(Stack)({
  position: 'relative',
  border: `1px solid ${switchComponentBackground}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.borderRadius.medium,
  padding: 24,
  pointerEvents: 'all',
  boxShadow: '0px 2.058px 1.934px 0px rgba(0, 0, 0, 0.0022)',
})

const CustomButton = styled(Button)({
  fontSize: '16px',
  letterSpacing: '0em',
  padding: 0,
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
})

export const ChartTooltip = ({
  active,
  payload,
  unit,
  tooltipPosition,
  chartType,
  aggregationType,
  setTooltipOpen,
  handleOnDateChange,
  chartOrigin,
  id,
  activeBar,
  maxPower,
  mesurementsType,
}: CustomTooltipProps) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement | null>(null)
  const [currentTooltipPosition, setCurrentTooltipPosition] = useState(0)
  const [currentPayload, setCurrentPayload] = useState<PayloadItem[] | undefined>(payload)
  const previousTooltipPosition = usePrevious(currentTooltipPosition)
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))

  const handleClickOutside = (event: MouseEvent) => {
    const customEvent: CustomEvent = event as CustomEvent
    if (customEvent.target.nodeName === 'rect' && chartOrigin === 'ComparationChart') return
    ref.current &&
      !ref.current.contains(customEvent.target as Node) &&
      customEvent.target.nodeName !== 'path' &&
      setTooltipOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    setCurrentTooltipPosition(tooltipPosition)
  }, [tooltipPosition])

  useEffect(() => {
    if ((previousTooltipPosition !== tooltipPosition && previousTooltipPosition !== 0) || isSmallMobile) {
      setCurrentPayload(payload)
    }
  }, [previousTooltipPosition, tooltipPosition, isSmallMobile, payload])

  if (!active || !currentPayload?.length) return null

  const { payload: currentPayloadValue } = currentPayload[0]

  const {
    amountLowRate: lowRateValue,
    amountHighRate: highRateValue,
    forecast: forecastValue,
    sumOfRates: sumOfRatesValue,
    noDataValue,
    energyMeasured,
    feedInMeasured,
    balance,
    feedIn,
    consumption,
    energy,
    description,
    timestampStartOfPeriod,
  } = currentPayloadValue

  const feedInValueForProsumerWhenMeasured =
    !isNil(feedIn) && !isNil(feedInMeasured)
      ? String(parseFloat(String(feedIn)) + parseFloat(String(feedInMeasured)))
      : null

  const energyValueForProsumerWhenMeasured =
    !isNil(energy) && !isNil(energyMeasured)
      ? String(parseFloat(String(energy)) + parseFloat(String(energyMeasured)))
      : null

  const comparisonPastConsumption = consumption
  const comparisonForecastConsumption = forecastValue
  const comparisonCurrentConsumption = consumption
  const comparisonEstimatedConsumption: number =
    Number(comparisonForecastConsumption) + Number(comparisonPastConsumption)

  const amountForecastIsZero = !!(forecastValue && Number(forecastValue) === 0)
  const comparisonForecastConsumptionIsZero = !!(
    comparisonForecastConsumption && Number(comparisonForecastConsumption) === 0
  )
  const comparisonCurrentConsumptionIsZero = !!(
    comparisonCurrentConsumption && Number(comparisonCurrentConsumption) === 0
  )

  const noDataValueInBarChart = chartOrigin === 'ChartBar' || (!noDataValue && !isItNullValue(feedIn))
  const isProsumer = mesurementsType === 'balance' || mesurementsType === 'feedIn' || mesurementsType === 'prosumer'

  const isConsumer = mesurementsType === 'consumption'

  const selectedTime = dayjs(timestampStartOfPeriod)
  const selectedTimeInterval = selectedTime.add(1, 'hour')
  const chartAreaTooltip = !!(chartOrigin && chartOrigin === 'ChartArea')
  const chartBarTooltip = !!(chartOrigin && chartOrigin === 'ChartBar')
  const comparisonChartTooltip = !!(chartOrigin && chartOrigin === 'ComparationChart')
  const paddingInComparationChart = chartOrigin === 'ComparationChart' ? { padding: '16px' } : { padding: '24px' }
  const dateFormatForMonth = chartType === chartAxisEnum.MONTH || aggregationType === 'month' ? 'MMMM YYYY' : 'YYYY'
  const translateForMonthAndDay =
    chartType === chartAxisEnum.MONTH || aggregationType === 'month'
      ? t('power_consumption_page.go_to_monthly_view')
      : t('power_consumption_page.go_to_daily_view')
  const tooltipDescription = (
    <Typography sx={{ fontSize: '16px' }} variant="bodyRegular">
      *{t(`power_consumption_page.${description}`)}
    </Typography>
  )

  const shouldShowFeedInItem = () => {
    if (isConsumer) return false
    if (!isItNullValue(feedInValueForProsumerWhenMeasured)) return false
    else if (mesurementsType === 'prosumer' && isItNullValue(feedIn) && !isItNullValue(energy)) return true
    else if (mesurementsType === 'balance' && isItNullValue(feedIn) && !isItNullValue(consumption)) return true
    else if (!isItNullValue(feedIn) && isItNullValue(feedInValueForProsumerWhenMeasured)) return true
    else if ((mesurementsType === 'feedIn' || isProsumer) && isItNullValue(feedIn)) return false
    else return false
  }

  const shouldShowEnergyItem = () => {
    if (mesurementsType !== 'feedIn' && maxPower?.timestamp !== timestampStartOfPeriod) {
      if (!isItNullValue(energyValueForProsumerWhenMeasured)) return false
      else if (mesurementsType === 'prosumer') {
        if (isItNullValue(energy) && !isItNullValue(feedIn)) return true
        else if (isItNullValue(feedIn) && !isItNullValue(energy)) return true
        else if (!isItNullValue(energy) && !isItNullValue(feedIn)) return true
        else return false
      } else if (mesurementsType === 'balance' && isItNullValue(energy) && !isItNullValue(consumption)) return true
      else if (isConsumer && !isNil(energy)) return true
      else if (isItNullValue(energy)) return false
      else return false
    }
    return false
  }

  const shouldRenderNoDataValueForCertainValues = () => {
    if (
      isProsumer &&
      !shouldShowEnergyItem() &&
      !shouldShowFeedInItem() &&
      isItNullValue(feedInValueForProsumerWhenMeasured) &&
      isItNullValue(energyValueForProsumerWhenMeasured)
    )
      return true
    else if (
      isConsumer &&
      isNil(lowRateValue) &&
      isNil(highRateValue) &&
      isNil(sumOfRatesValue) &&
      !shouldShowEnergyItem() &&
      isNil(energy) &&
      isItNullValue(energyValueForProsumerWhenMeasured)
    )
      return true

    return false
  }

  const shouldShowNoDataForConsumptionOrFeedInValues = () => {
    if (mesurementsType === 'balance') {
      if (!isItNullValue(consumption) && isItNullValue(feedIn)) return true
      else if (!isItNullValue(feedIn) && isItNullValue(consumption)) return true
    }

    if (mesurementsType === 'prosumer') {
      if (!isItNullValue(feedInValueForProsumerWhenMeasured) && isItNullValue(energy)) return true
      else if (!isItNullValue(energyValueForProsumerWhenMeasured) && isItNullValue(feedIn)) return true
      else if (!isItNullValue(feedIn) && isItNullValue(energy)) return true
      else if (!isItNullValue(energy) && isItNullValue(feedIn)) return true
    }

    return false
  }

  return (
    <Box ref={ref} id={id}>
      <CustomBox
        sx={
          chartOrigin && chartOrigin === 'ChartArea'
            ? { padding: '16px', minWidth: '228px', maxWidth: 'max-content' }
            : paddingInComparationChart
        }
        id="customBox"
      >
        {chartType || aggregationType ? (
          <Typography variant="body1" sx={{ margin: '0 0 5px 0' }}>
            {dayjs(timestampStartOfPeriod).format(chartType === chartAxisEnum.DAY ? 'DD MMM YYYY' : dateFormatForMonth)}
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ margin: '0 0 5px 0' }}>
            {`${selectedTime.format('HH:mm')} - ${selectedTimeInterval.format('HH:mm')} ${t('hour')}`}
          </Typography>
        )}
        <Box>
          <Show when={!comparisonChartTooltip}>
            <FeedInTooltip
              shouldShowFeedInItem={shouldShowFeedInItem()}
              t={t}
              unit={unit}
              color={prosumerPalette.prosumerFeedIn}
              feedIn={feedIn}
              feedInValueForProsumerWhenMeasured={feedInValueForProsumerWhenMeasured}
              feedInMeasured={feedInMeasured}
            />

            <EnergyTooltip
              shouldShowEnergyItem={shouldShowEnergyItem()}
              energy={energy}
              t={t}
              unit={unit}
              color={prosumerPalette.prosumerConsumption}
              energyValueForProsumerWhenMeasured={energyValueForProsumerWhenMeasured}
              energyMeasured={energyMeasured}
            />
            {/* Forcast */}

            <Show when={!amountForecastIsZero && !isNil(forecastValue)}>
              <ConsumptionItem
                label={t('power_consumption_page.forecast')}
                value={String(Number(forecastValue).toFixed(2))}
                unit={unit}
                showSquare={true}
                color={chartPallete.bar.forecast.fill}
                border={chartPallete.bar.forecast.stroke}
                componentVariant="ChartTooltip"
              />
            </Show>

            {/* max power */}

            <Show when={!!maxPower && maxPower.timestamp === timestampStartOfPeriod}>
              <ConsumptionItem
                label={t('consumption.maxPower')}
                value={maxPower?.maxPower ? String(Number(maxPower.maxPower).toFixed(2)) : null}
                unit={unit}
                showSquare={true}
                maxPower
                color={chartPallete.maxPower.icon}
                componentVariant="ChartTooltip"
              />
            </Show>

            {/* consumption item in balance */}

            <Show
              when={
                (mesurementsType === 'balance' && !isItNullValue(consumption) && isNil(energy)) ||
                (mesurementsType === 'balance' && !isItNullValue(feedIn) && isNil(energy))
              }
            >
              <ConsumptionItem
                label={t('prosumer.prosumer_consumption')}
                value={
                  consumption !== undefined && !isItNullValue(consumption)
                    ? String(Number(consumption).toFixed(2))
                    : null
                }
                unit={unit}
                showSquare={true}
                color={isItNullValue(consumption) ? 'transparent' : prosumerPalette.prosumerConsumption}
                border={isItNullValue(consumption) ? 'rgba(141, 150, 141, 0.5)' : undefined}
                componentVariant="ChartTooltip"
              />
            </Show>

            <Show
              when={
                isConsumer &&
                (chartAreaTooltip || chartBarTooltip || !chartOrigin) &&
                !energy &&
                mesurementsType === 'consumption' &&
                ((maxPower && maxPower.timestamp !== timestampStartOfPeriod) || !maxPower)
              }
            >
              {
                <ConsumptionTooltip
                  lowRateValue={lowRateValue}
                  highRateValue={highRateValue}
                  sumOfRatesValue={sumOfRatesValue}
                  unit={unit}
                  t={t}
                />
              }
            </Show>

            {/* no data message for when we have two values
              but one of them is available */}
            <Show when={shouldShowNoDataForConsumptionOrFeedInValues()}>
              <Typography variant="bodyRegular" width={'280px'}>
                {t('power_consumption_page.error_message_no_data_available_for_time_consumption_or_feedIn')}
              </Typography>
            </Show>
            {/* No value  message */}
            <Show when={shouldRenderNoDataValueForCertainValues()}>
              <Typography variant="bodyRegular" width={'280px'}>
                <Trans i18nKey="power_consumption_page.error_message_no_data_available_for_time_period_description_new" />
              </Typography>
            </Show>
          </Show>
          <Show when={comparisonChartTooltip}>
            <Box>
              <Show when={isConsumer}>
                <Show when={!comparisonForecastConsumptionIsZero}>
                  <ConsumptionItem
                    label={t('power_consumption_page.past_consumption')}
                    value={String(Number(comparisonPastConsumption).toFixed(2))}
                    unit={unit}
                    showSquare={true}
                    color={comparisonPalette.forecast.past}
                    componentVariant="ChartTooltip"
                  />
                </Show>
                <Show when={!comparisonForecastConsumptionIsZero}>
                  <ConsumptionItem
                    label={t('power_consumption_page.forecast')}
                    value={String(Number(comparisonForecastConsumption).toFixed(2))}
                    unit={unit}
                    showSquare={true}
                    color={comparisonPalette.forecast.estimated}
                    componentVariant="ChartTooltip"
                  />
                </Show>
                <Show when={comparisonCurrentConsumptionIsZero || comparisonForecastConsumptionIsZero}>
                  <ConsumptionItem
                    label={t('power_consumption_page.consumption')}
                    value={String(Number(comparisonCurrentConsumption).toFixed(2))}
                    unit={unit}
                    showSquare={true}
                    color={comparisonPalette.consumption.fill}
                    componentVariant="ChartTooltip"
                  />
                </Show>
              </Show>
              <Show when={isProsumer}>
                <Show when={activeBar === 'feedIn'}>
                  <ProsumerItem
                    label={t('prosumer.prosumer_feed_in')}
                    value={feedIn !== undefined ? String(Number(feedIn).toFixed(2)) : null}
                    unit={unit}
                    showSquare={true}
                    color={prosumerPalette.prosumerFeedIn}
                    componentVariant="ChartTooltip"
                  />
                </Show>
                <Show when={activeBar === 'balance'}>
                  <ProsumerItem
                    label={t('prosumer.prosumer_balance')}
                    value={balance !== undefined ? String(Number(balance).toFixed(2)) : null}
                    unit={unit}
                    showSquare={true}
                    color={
                      Number(balance) < 0 ? prosumerPalette.prosumerFeedInNegative : comparisonPalette.consumption.fill
                    }
                    componentVariant="ChartTooltip"
                  />
                </Show>
              </Show>
            </Box>
          </Show>
        </Box>

        <Show when={!chartOrigin || chartBarTooltip || comparisonChartTooltip}>
          <Box>
            <Show
              when={(!chartOrigin || chartBarTooltip || (comparisonChartTooltip && !amountForecastIsZero)) && !energy}
            >
              <Show when={noDataValueInBarChart}>
                <Divider sx={{ mt: 1, mb: isConsumer ? 1 : 0, visibility: isProsumer ? 'hidden' : 'auto' }} />

                <Show when={!isProsumer && amountForecastIsZero}>
                  <ConsumptionItem
                    label={t('power_consumption_page.total_consumption')}
                    value={String(Number(sumOfRatesValue).toFixed(2))}
                    unit={unit}
                    showSquare={false}
                    componentVariant="ChartTooltip"
                  />
                </Show>
                <Show when={!amountForecastIsZero}>
                  <ConsumptionItem
                    label={t('power_consumption_page.estimated_consumption')}
                    value={String(Number(comparisonEstimatedConsumption).toFixed(2))}
                    unit={unit}
                    showSquare={false}
                    componentVariant="ChartTooltip"
                  />
                </Show>
              </Show>
            </Show>

            <Show when={noDataValueInBarChart}>
              <CustomButton
                onClick={() => {
                  handleOnDateChange?.(timestampStartOfPeriod)
                  setTooltipOpen(false)
                }}
                variant={'text'}
                endIcon={<ArrowForwardIcon />}
              >
                {chartType === chartAxisEnum.YEAR || aggregationType === 'year'
                  ? t('power_consumption_page.go_to_yearly_view')
                  : translateForMonthAndDay}
              </CustomButton>
            </Show>
          </Box>
        </Show>

        <Show when={!!description}>{tooltipDescription}</Show>
      </CustomBox>
    </Box>
  )
}

export default ChartTooltip
