import ContactSupportContent from '@components/DtpConnectAccountWizard/common/ContactSupportContent'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Typography, IconButton, Stack, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ContactSupport = ({
  goBack,
  accountNumber,
  onClose,
}: {
  goBack: () => void
  accountNumber?: string
  onClose?: () => void
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  return (
    <Stack height="100%">
      <Stack mb={4} direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="heading3" sx={{ my: 0, mt: 0.5 }}>
          {t('authorization.no_contact_details_available')}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>
      <ContactSupportContent accountNumber={accountNumber} />
      <Box mt={'auto'}>
        <Button variant="outlined" size="large" fullWidth={isMobile} onClick={goBack}>
          {t('button_close')}
        </Button>
      </Box>
    </Stack>
  )
}

export default ContactSupport
