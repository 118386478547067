import { getRefreshToken } from '@api/queries'
import { isEmpty } from '@helpers/functional'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { Role, useAuthStore } from '@src/stores/auth/useAuthStore'
import { useQuery } from '@tanstack/react-query'
import { jwtDecode, JwtPayload } from 'jwt-decode'

interface KeyCloakToken extends JwtPayload {
  realm_access: {
    roles: string[]
  }
}

const useRefreshToken = () => {
  const { refetch } = useAccounts()
  const setRoles = useAuthStore((state) => state.setRoles)
  const token = localStorage.getItem('ckw-refresh-token') ?? ''

  const { refetch: fetchRefreshToken } = useQuery({
    queryKey: ['refreshToken'],
    queryFn: () => getRefreshToken(JSON.parse(token)),
    enabled: false,
  })

  const refreshToken = async () => {
    const { data } = await fetchRefreshToken()
    if (!isEmpty(data)) {
      localStorage.setItem('ckw-token', JSON.stringify(data.access_token))
      localStorage.setItem('ckw-refresh-token', JSON.stringify(data.refresh_token))
      const decodedToken = jwtDecode<KeyCloakToken>(data.access_token)
      const roles = decodedToken.realm_access.roles as Role[]
      setRoles(roles)
      await refetch()
    }
  }

  return { refreshToken }
}

export default useRefreshToken
