import { useFeatureToggle } from '@hooks/useFeatureToggle/useFeatureToggle'
import { useAccountStore } from '@src/stores/accounts/useAccountStore'
import { useAuthStore, Role } from '@src/stores/auth/useAuthStore'
import { useLocationStore } from '@src/stores/locations/useLocationStore'
import Maintenance from '@src/ui/wrappers/Maintenance/Maintenance'
import Keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakTokenParsed } from 'keycloak-js'
import React, { createContext, Suspense, useCallback, useEffect, useMemo, useState } from 'react'

import { SuspenseLoading } from '../ui/components/SuspenseLoading/SuspenseLoading'

type AuthContextValues = {
  isAuthenticated: boolean
  logout: () => void
  hasRole: (role: string) => boolean
  keycloakError: boolean
  getKeycloakAccountPage: () => void
  user?: KeycloakTokenParsed
  clearToken: () => void
}
const keycloakURL = process.env.REACT_APP_KEYCLOAK_URL
const keycloakRealm = String(process.env.REACT_APP_KEYCLOAK_REALM)
const keycloakClientId = String(process.env.REACT_APP_KEYCLOAK_CLIENT_ID)

const keycloakConfig: KeycloakConfig = {
  url: keycloakURL,
  realm: keycloakRealm,
  clientId: keycloakClientId,
}

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  flow: window.location.origin.includes('-dtp-') ? 'implicit' : 'standard',
}

export const keycloak = new Keycloak(keycloakConfig)

const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  keycloakError: false,
  logout: () => {},
  hasRole: () => false,
  getKeycloakAccountPage: () => {},
  clearToken: () => {},
}

const resetActiveAccount = useAccountStore.getState().resetActiveAccount
const resetLocations = useLocationStore.getState().resetLocations
const setRoles = useAuthStore.getState().setRoles
const clearRoles = useAuthStore.getState().clearRoles

export const AuthContext = createContext<AuthContextValues>(defaultAuthContextValues)

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [keycloakError, setKeycloakError] = useState<boolean>(false)
  const [user, setUser] = useState<KeycloakTokenParsed | undefined>(undefined)
  const { features } = useFeatureToggle()
  const isMaintenance = features['maintenance']

  const initializeKeycloak = useCallback(async () => {
    setKeycloakError(false)

    try {
      const isAuthenticatedResponse = await keycloak.init(keycloakInitOptions)
      if (!isAuthenticatedResponse) {
        keycloak.login()
      }
      setIsAuthenticated(isAuthenticatedResponse)
      localStorage.setItem('ckw-token', JSON.stringify(keycloak.token))
      localStorage.setItem('ckw-refresh-token', JSON.stringify(keycloak.refreshToken))
      setUser(keycloak.idTokenParsed)
      setRoles(keycloak.realmAccess?.roles as Role[])
    } catch (error) {
      setIsAuthenticated(false)
      setKeycloakError(true)
      localStorage.removeItem('ckw-token')
      localStorage.removeItem('ckw-refresh-token')
    }
  }, [])

  useEffect(() => {
    if (isMaintenance === false) {
      initializeKeycloak()
    }
  }, [initializeKeycloak, isMaintenance])

  const logout = useCallback(async () => {
    try {
      setIsAuthenticated(false)
      await keycloak.logout()
      clearRoles()
      resetActiveAccount()
      resetLocations()
      localStorage.removeItem('ckw-token')
      localStorage.removeItem('ckw-refresh-token')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('keycloak-logout error:', error)
    }
  }, [])

  keycloak.onTokenExpired = async () => {
    try {
      const isRefreshed = await keycloak.updateToken(5)
      if (isRefreshed) {
        localStorage.setItem('ckw-token', JSON.stringify(keycloak.token))
      }
    } catch (error) {
      localStorage.removeItem('ckw-token')
      keycloak.logout()
      throw error
    }
  }

  useEffect(() => {
    keycloak.onAuthLogout = () => {
      resetActiveAccount()
      resetLocations()
      clearRoles()
      localStorage.removeItem('ckw-token')
      localStorage.removeItem('ckw-refresh-token')
    }
  }, [])

  const hasRole = useCallback((role: string) => keycloak.hasRealmRole(role), [])
  const getKeycloakAccountPage = useCallback(() => keycloak?.accountManagement(), [])
  const clearToken = useCallback(() => keycloak.clearToken(), [])

  const contextValue = useMemo(() => {
    return { isAuthenticated, keycloakError, logout, hasRole, user, getKeycloakAccountPage, clearToken }
  }, [isAuthenticated, keycloakError, logout, hasRole, user, getKeycloakAccountPage, clearToken])

  if (isMaintenance) {
    return (
      <Suspense fallback={<SuspenseLoading />}>
        <Maintenance />
      </Suspense>
    )
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {isAuthenticated || keycloakError ? children : <SuspenseLoading />}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
