import { invoiceEmailConfirmation, invoiceEmailInitiation, authorizeUserAccount } from '@api/mutations'
import { getAutomaticAuth } from '@api/queries'
import { removeBlankSpaces } from '@helpers/functional'
import useNotification from '@hooks/useNotification/useNotification'
import useRefreshToken from '@hooks/useRefreshToken/useRefreshToken'
import { authorizeUserSchema } from '@src/validations/authorizationValidationSchema'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

const useAuthorizeUser = (setOpen?: (open: boolean) => void) => {
  const notification = useNotification()
  const { refreshToken } = useRefreshToken()
  const { t } = useTranslation()

  const authorizeUserForm = useFormik({
    validationSchema: authorizeUserSchema,
    validateOnChange: true,
    initialValues: {
      gpnr: '',
      evidence: '',
    },
    onSubmit: async ({ gpnr, evidence }) =>
      authorizeAccount({ gpnr: removeBlankSpaces(gpnr), evidence: removeBlankSpaces(evidence) }),
  })

  const { data: automaticAuth, isLoading: isLoadingAutomaticAuth } = useQuery({
    queryKey: ['automaticAuthPossibility'],
    queryFn: getAutomaticAuth,
  })

  const { mutateAsync: emailIntiation } = useMutation({
    mutationKey: ['resendEmailAuth'],
    mutationFn: invoiceEmailInitiation,
  })

  const { mutateAsync: emailConfirmation } = useMutation({
    mutationKey: ['confirmEmail'],
    mutationFn: invoiceEmailConfirmation,
    onSuccess: async (data) => {
      if (data?.body?.numberOfGpnrsAuthorized > 0) {
        notification('success', t('toast.account_added_title'), t('toast.account_added_description'))
        await refreshToken()
      } else {
        notification('error', t('authorization.add_account_failed'), t('authorization.add_account_failed_explanation'))
      }
    },
  })

  const { mutateAsync: authorizeAccount, isPending: isAuthorizingAccount } = useMutation({
    mutationKey: ['authorizeAccount'],
    mutationFn: authorizeUserAccount,
    onSuccess: async (data) => {
      if (data?.body?.isUserAuthorized) {
        notification('success', t('toast.account_added_title'), t('toast.account_added_description'))
        await refreshToken()
        setOpen && setOpen(false)
      } else {
        notification('error', t('authorization.add_account_failed'), t('authorization.add_account_failed_explanation'))
      }
    },
  })

  const canAuthorizeAutomatically = automaticAuth?.body?.authorizationPossible ?? false

  return {
    canAuthorizeAutomatically,
    isLoadingAutomaticAuth,
    emailIntiation,
    emailConfirmation,
    authorizeAccount,
    authorizeUserForm,
    isAuthorizingAccount,
  }
}

export default useAuthorizeUser
