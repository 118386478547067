import { statusType } from '@components/DtpInvoiceCard/DtpInvoiceCardUtills'
import { invoiceStatus } from '@enums/invoiceStatus'
import { Typography, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

const InvoiceStatus = ({ title, invoiceStatus }: { title: string; invoiceStatus?: invoiceStatus }) => {
  const status = invoiceStatus ? statusType[invoiceStatus] : undefined

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="bodySmall" fontFamily="GothamBook" sx={{ my: 0.5 }}>
        {title}
      </Typography>
      <Show when={!!status}>
        <Stack flexDirection="row" alignItems="center">
          {status?.icon}
          <Typography variant="bodySmall" color={status?.textColor} fontFamily="GothamBook" sx={{ my: 0.5 }}>
            {status?.text}
          </Typography>
        </Stack>
      </Show>
    </Stack>
  )
}

export default InvoiceStatus
