import { MessageIcon, EmailIcon } from '@assets/icons'
import { AuthOptions } from '@enums/authorization'
import { Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import React from 'react'
import { useTranslation } from 'react-i18next'

import MethodSelectCard from './MethodSelectCard'
import SelectDeliveryOption from './SelectDeliveryOption'

interface ICodeDeliveryOptionsProps {
  availableDeliveryOptions: {
    [key: string]: boolean
  }
  authOptions: Partial<Record<AuthOptions, string>>
  selectedOption: AuthOptions
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CodeDeliveryOptions = ({
  availableDeliveryOptions,
  authOptions,
  selectedOption,
  onChange,
}: ICodeDeliveryOptionsProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Show when={availableDeliveryOptions.all}>
        <SelectDeliveryOption authOptions={authOptions} selectedOption={selectedOption} onChange={onChange} />
      </Show>

      <Show when={availableDeliveryOptions.sms}>
        <MethodSelectCard
          icon={<MessageIcon />}
          title={t('authorization.by_sms')}
          description={`${t('authorization.to')} ${authOptions.mobileNumber}`}
        />
      </Show>

      <Show when={availableDeliveryOptions.email}>
        <MethodSelectCard
          icon={<EmailIcon />}
          title={t('authorization.by_e_mail')}
          description={`${t('authorization.to')} ${authOptions.invoiceEmail}`}
        />
      </Show>
      <Show when={availableDeliveryOptions.none}>
        <Typography variant="bodyRegular" sx={{ opacity: 0.7 }}>
          {t('authorization.no_access_code_options')}
        </Typography>
      </Show>
    </>
  )
}

export default CodeDeliveryOptions
