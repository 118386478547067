import BackgroundImage from '@assets/images/ckw-home-backgroud.png'
import { Box, styled } from '@mui/material'

const CustomBox = styled(Box)(({ theme }) => ({
  background: `url(${BackgroundImage})`,
  backgroundPosition: '0% 0%',
  backgroundSize: '60%',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('md')]: {
    backgroundSize: '100%',
  },
}))

const HomeWrapper = ({ children }: { children: React.ReactNode }) => {
  return <CustomBox>{children}</CustomBox>
}

export default HomeWrapper
