import CodeDeliveryOptions from '@components/DtpConnectAccountWizard/common/CodeDeliveryOptions'
import NoAccountControls from '@components/DtpConnectAccountWizard/common/NoAccountControls'
import Wrapper from '@components/DtpConnectAccountWizard/common/Wrapper'
import { AuthOptions, FormSteps } from '@enums/authorization'
import useNotification from '@hooks/useNotification/useNotification'
import { Box, Typography, Button, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

const mediumMap = {
  invoiceEmail: 'E-mail',
  mobileNumber: 'SMS',
}

export interface IRequestNewCodeProps {
  authOptions: Partial<Record<AuthOptions, string>>
  goTo: (step: string) => void
  requestAccessCode: (medium: 'invoiceEmail' | 'mobileNumber') => Promise<{ body: null }>
  setSelectedOption: (method: AuthOptions) => void
  selectedOption: AuthOptions
  availableDeliveryOptions: {
    [key: string]: boolean
  }
}

const RequestNewCode = ({
  goTo,
  authOptions,
  requestAccessCode,
  selectedOption,
  setSelectedOption,
  availableDeliveryOptions,
}: IRequestNewCodeProps) => {
  const { t } = useTranslation()
  const notification = useNotification()

  const handleRequestAccessCode = async () => {
    try {
      await requestAccessCode(selectedOption)
      notification(
        'success',
        t('authorization.access_code_sent_title'),
        t('authorization.access_code_sent_description', { medium: mediumMap[selectedOption] })
      )
      goTo(FormSteps.ACCESS_CODE_STEP)
    } catch (error) {
      notification('error')
    }
  }

  return (
    <Wrapper>
      <Box>
        <Typography variant="heading3" sx={{ mt: 0, mb: 2 }}>
          {t('authorization.request_access_code')}
        </Typography>
        <Typography variant="bodyRegular" sx={{ mb: { xs: 2, sm: 4 } }}>
          {t('authorization.choose_access_code_option')}
        </Typography>
        <CodeDeliveryOptions
          authOptions={authOptions}
          availableDeliveryOptions={availableDeliveryOptions}
          selectedOption={selectedOption}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedOption(e.target.value as AuthOptions)}
        />
        <Box mt={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Show when={!availableDeliveryOptions.none}>
              <Button variant="contained" size="large" onClick={handleRequestAccessCode}>
                {t('authorization.request_code')}
              </Button>
            </Show>
            <Button variant="outlined" size="large" onClick={() => goTo(FormSteps.ACCESS_CODE_STEP)}>
              {t('back')}
            </Button>
          </Stack>
        </Box>
        <NoAccountControls onClick={() => goTo(FormSteps.SUPPORT)} />
      </Box>
    </Wrapper>
  )
}

export default RequestNewCode
