import React from 'react'
import { ReferenceLine } from 'recharts'

import { ReferenceAreaForNullValuesProps } from './ReferenceAreaForNullValues'

const ReferenceLineForNullValues = (props: ReferenceAreaForNullValuesProps) => {
  const { groupedNullIndexes, data, ticks } = props

  if (groupedNullIndexes?.length) {
    return groupedNullIndexes.map((nullIndexes, i) => (
      <ReferenceLine
        key={i + nullIndexes[0] + 2}
        segment={[
          { x: data[nullIndexes[0] - 1]?.timestampStartOfPeriod, y: Number(ticks[2]) - 1.5 },
          {
            x: data[nullIndexes[nullIndexes.length - 1] + 1]?.timestampStartOfPeriod,
            y: Number(ticks[2]) - 1.5,
          },
        ]}
        strokeDasharray={'5 5'}
        stroke="rgba(141, 150, 141, 0.2)"
        strokeWidth={4}
        ifOverflow="extendDomain"
      />
    ))
  } else return null
}

export default ReferenceLineForNullValues
