import { getLastAvailableDay } from '@api/queries'
import { IMeasurementResponse } from '@interfaces/measurements'
import { useQuery } from '@tanstack/react-query'

interface ILastAvailableDayProps {
  gpnr: string
  meterId?: string
  locationId?: string
  contractId?: string
}

export const useLastAvailableDay = ({ gpnr, meterId, locationId, contractId }: ILastAvailableDayProps) => {
  const { data: lastAvailableDay, isPending: isLoadingLastAvailableData } = useQuery({
    queryKey: ['lastAvailableDay', gpnr, meterId, locationId, contractId],
    queryFn: () =>
      getLastAvailableDay({
        gpnr: gpnr,
        meterId: meterId ?? undefined,
        locationId: locationId ?? undefined,
        contractId: contractId ?? undefined,
      }),
    enabled: !!gpnr && ((!!contractId && !!meterId) || !!locationId),
    gcTime: 0,
    select: (data: IMeasurementResponse) => data?.body?.measurements[0]?.timestampStartOfPeriod,
  })

  return { lastAvailableDay, isLoadingLastAvailableData }
}
