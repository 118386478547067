import theme from '@assets/themes/theme'
import { Box, Tab, Tabs, styled, Stack } from '@mui/material'
import React from 'react'

interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface IDtpTabsProps {
  tabs: { label: string; component: React.ReactNode }[]
  activeTab?: number
  onChange?: (_: React.SyntheticEvent, newValue: number) => void
  onClick?: () => void
  controlsSlot?: React.ReactNode
}

const CustomTabControl = styled(Tabs)({
  '& .MuiTab-root': {
    borderRadius: theme.borderRadius.large,
    fontSize: theme.typography.pxToRem(16),
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
})

const CustomTabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </Box>
  )
}

const DtpTabs = ({ tabs, activeTab, onChange, onClick, controlsSlot }: IDtpTabsProps) => {
  return (
    <>
      <Stack
        my={3}
        gap={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        overflow="hidden"
      >
        <CustomTabControl value={activeTab} onChange={onChange} aria-label="Date agregation" variant="scrollable">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              sx={{ textTransform: 'capitalize' }}
              onClick={index === 3 ? onClick : undefined}
            />
          ))}
        </CustomTabControl>
        <Box width={{ xs: '100%', sm: 'auto' }}>{controlsSlot}</Box>
      </Stack>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={activeTab || 0} index={index}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </>
  )
}

export default DtpTabs
